@charset "utf-8";




/*リセットCSS（sanitize.css）の読み込み
---------------------------------------------------------------------------*/
@import url("https://unpkg.com/sanitize.css");

/*slick.cssの読み込み
---------------------------------------------------------------------------*/
@import url("https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css");

/*Font Awesomeの読み込み
---------------------------------------------------------------------------*/
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

/*Google Fontsの読み込み
---------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&display=swap');

/*テンプレート専用cssファイルの読み込み
---------------------------------------------------------------------------*/
@import url("animation.css");
@import url("inview.css");





/*全体の設定
---------------------------------------------------------------------------*/
html,body {
	margin: 0;padding: 0;
	height: 100%;
	font-size: 13px;	/*基準となるフォントサイズ。下の方にある「画面幅900px以上」で基準を大きなサイズに再設定しています。*/
}

body {
	font-family: 'BIZ UDPGothic', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;	/*フォント種類*/
	-webkit-text-size-adjust: none;
	background: #fff;	/*背景色*/
	color: #555;		/*全体の文字色*/
	line-height: 2;		/*行間*/
	overflow-x: hidden;
}

/*リセット*/
figure {margin: 0;}
dd {margin: 0;}
nav {margin: 0;padding: 0;}

/*table全般の設定*/
table {border-collapse:collapse;}

/*画像全般の設定*/
img {border: none;max-width: 100%;height: auto;vertical-align: middle;}

/*videoタグ*/
video {max-width: 100%;}

/*iframeタグ*/
iframe {width: 100%;}

/*ul,olタグ*/
ul, ol {margin-bottom: 30px;}


/*リンクテキスト全般の設定
---------------------------------------------------------------------------*/
a {
	color: #57524b;	/*文字色*/
	transition: 0.3s;
}

a:hover {
	opacity: 0.8;	/*マウスオン時に80%の透明度にする*/
}


/*sectionタグと、詳細ページの共通設定
---------------------------------------------------------------------------*/
section,
main > article {
	margin: 0 auto;
	max-width: 1300px;	/*最大幅。これ以上広がらない。*/
	padding: 2% 5%;		/*ボックス内の余白*/
}


/*containerブロック
---------------------------------------------------------------------------*/
#container {
	height: 100%;
	display: flex;					/*flexボックスを使う指定*/
	flex-direction: column;			/*子要素を縦並びにする*/
	justify-content: space-between;	/*並びかたの種類の指定*/
}


/*header（ロゴなどが入った最上段のブロック）
---------------------------------------------------------------------------*/
/*ヘッダーブロック*/
header {
	display: flex;				/*flexボックスを使う指定*/
	align-items: center;		/*垂直揃えの指定。上下中央に配置されるように。*/
	justify-content: flex-end;	/*並びかたの種類の指定*/
	background: #111;			/*背景色*/
	color: #fff;				/*文字色*/
	position: relative; z-index: 1;
}

/*ヘッダーのリンクテキストの文字色*/
header a {
	color: #fff;
}

/*ロゴ画像*/
header #logo img {display: block;}
header #logo {
	margin: 0;
	background: linear-gradient(150deg, #ff0e0e, #770000);/*背景グラデーションdegは角度。*/
	width: 150px;		/*ロゴの幅*/
	padding: 40px 20px;	/*ロゴ内の余白。ロゴ画像と背景グラデーションの余白です。*/
	position: absolute;
	left: 3%;	/*ヘッダーの左からの配置場所の指定*/
	top: 0px;	/*ヘッダーの上からの配置場所の指定*/
	box-shadow: 0px 0px 30px rgba(119,0,0,0.2);	/*ボックスの影。右へ、下へ、ぼかし幅の順番。rgbaの数字は、最初の３つがRGBでの色指定で最後の小数点が透明度の指定。*/
}


/*menubarブロック初期設定
---------------------------------------------------------------------------*/
#menubar ul {list-style: none;margin: 0;padding: 0;}

/*メニューを非表示にしておく*/
#menubar {display: none;}

/*開閉用のスタイル*/
#menubar.db {display: block;}
#menubar.dn {display: none;}

/*メニュー１個あたりの設定*/
#menubar a {
	display: block;text-decoration: none;
	text-align: center;		/*テキストを中央に*/
	letter-spacing: 0.1em;	/*文字間隔を少しだけ広くする指定*/
}


/*小さな端末時にアイコン類だけ横並びにする
---------------------------------------------------------------------------*/
.s #menubar.db li.inline,
.s #menubar.db li.inline a {
	display: inline-block;
}


/*小さな端末用の開閉ブロック設定
---------------------------------------------------------------------------*/
/*メニューブロック設定*/
.s #menubar.db {
	position: fixed;overflow: auto;z-index: 100;
	left: 0px;top: 0px;
	width: 100%;
	height: 100%;
	padding: 70px 0;	/*上下、左右へのブロック内の余白*/
	background: rgba(0,0,0,0.9);		/*背景色。0,0,0は黒の事で0.9は色が90%出た状態の事。*/
	color: #fff;						/*文字色*/
	animation: animation1 0.2s both;	/*animation.cssの、animation1を実行する。0.2sは0.2秒の事。*/
	text-align: center;
}

/*メニュー１個あたりの設定*/
.s #menubar.db a {
	color: #fff;	/*文字色*/
	padding: 20px;	/*メニュー内の余白*/
}


/*ドロップダウンメニュー
---------------------------------------------------------------------------*/
/*ドロップダウンを非表示にしておく*/
#menubar .ddmenu_parent ul {
	display: none;
}

/*ドロップダウンメニューを持つ親に矢印アイコンをつける設定*/
a.ddmenu::before {
	font-family: "Font Awesome 5 Free";	/*Font Awesomeを使う指定*/
	content: "\f103";		/*使いたいアイコン名をここで指定。Font Awesomeに記載されています。詳しくは当テンプレートのマニュアルを読んで下さい。*/
	font-weight: bold;		/*この手の設定がないとアイコンが出ない場合があります*/
	margin-right: 0.5em;	/*アイコンとテキストとの間に空けるスペース*/
}

/*ドロップダウンを持つ親のマウスオン時にリンク用のカーソルでなくデフォルトの矢印を出す*/
a.ddmenu {
	cursor: default;
}

/*ドロップダウンメニュー１個あたりの設定*/
.ddmenu_parent ul a {
	background: rgba(0,0,0,0.8);	/*背景色。0,0,0は黒の事で0.8は色が80%出た状態の事。*/
}


/*３本バー（ハンバーガー）アイコン設定
---------------------------------------------------------------------------*/
/*開閉用のスタイル*/
#menubar_hdr.db {display: flex;}
#menubar_hdr.dn {display: none;}

/*３本バーを囲むブロック*/
#menubar_hdr {
	position: fixed;z-index: 101;
	cursor: pointer;
	right: 3%;			/*左からの配置場所指定*/
	top: 0px;			/*上からの配置場所指定*/
	padding: 16px 14px;	/*上下、左右への余白*/
	width: 52px;		/*幅（３本バーが出ている場合の幅になります）*/
	height: 52px;		/*高さ*/
	display: flex;					/*flexボックスを使う指定*/
	flex-direction: column;			/*子要素（３本バー）を縦並びにする*/
	justify-content: space-between;	/*並びかたの種類の指定*/
	order: 0;						/*表示させる順番。「#menubar_hdr」「#logo」「#header-icon」それぞれに指定しており、数字の「小さな順」に左から並びます。*/
	background: rgba(0,0,0,0.5);	/*背景色*/
}

/*バー１本あたりの設定*/
#menubar_hdr span {
	display: block;
	transition: 0.3s;	/*アニメーションにかける時間。0.3秒。*/
	border-top: 2px solid #fff;	/*線の幅、線種、色*/
}

/*×印が出ている状態の設定。※１本目および２本目のバーの共通設定。*/
#menubar_hdr.ham span:nth-of-type(1),
#menubar_hdr.ham span:nth-of-type(3) {
	transform-origin: center center;	/*変形の起点。センターに。*/
	width: 26px;						/*バーの幅*/
}

/*×印が出ている状態の設定。※１本目のバー。*/
#menubar_hdr.ham span:nth-of-type(1){
	transform: rotate(45deg) translate(6px, 7px);	/*回転45°と、X軸Y軸への移動距離の指定*/
}

/*×印が出ている状態の設定。※３本目のバー。*/
#menubar_hdr.ham span:nth-of-type(3){
	transform: rotate(-45deg) translate(6px, -7px);	/*回転-45°と、X軸Y軸への移動距離の指定*/
}

/*×印が出ている状態の設定。※２本目のバー。*/
#menubar_hdr.ham span:nth-of-type(2){
	display: none;	/*２本目は使わないので非表示にする*/
}


/*mainブロック設定
---------------------------------------------------------------------------*/
/*mainブロックの設定*/
main {	
	flex: 1;
}

/*トップページ以外のページのmain上部に余白をとる*/
body:not(.home) main {
	padding-top: 80px;
}

/*mainブロック内のh2タグ*/
main h2 {
	margin-bottom: 30px;	/*下に空けるスペース*/
	position: relative;		/*アニメーションに必要な設定*/
	letter-spacing: 0.1em;	/*文字間隔を少しだけ広くとる設定*/
	border-bottom: 3px solid #f5f5f5;	/*デフォルトのラインの色。下の「bottom」と「height」の３ヶ所の数字部分を合わせる*/
}

/*下線スタイル*/
main h2 .uline {
	display: inline-block;position: relative;
	padding: 10px 20px;	/*h2タグ内の余白。上下、左右への順番。*/
	bottom: -3px;		/*上の「border-bottom」と下の「height」の３ヶ所の数字部分を合わせる。※この行だけマイナスをつけるように。*/
}
main h2 .uline::before {
	content: "";position: absolute;bottom: 0px;left: 0px;width: 100%;
	height: 3px;	/*ラインの高さ。上の「border-bottom」と「bottom」の３ヶ所の数字部分を合わせる*/
	background: #111;			/*アニメーション時のラインの色*/
	transition: 1s 0.5s;		/*1sはアニメーションの実行時間は1秒。0.5秒遅れてスタートする指定。*/
	transform: scaleX(0);		/*幅。最初は0にして見えなくしておく。*/
	transform-origin: left top;	/*線の出現起点が左からになるように。中央からの出現がよければこの１行削除。*/
}
main h2.linestyle .uline::before {
	transform: scaleX(1);
}

/*mainブロック内のh3タグ*/
main h3 {
	margin-bottom: 20px;	/*下に空けるスペース*/
}

/*mainブロックのh3タグの冒頭の装飾設定*/
main h3::before {
	display: inline-block;
	content: "■";			/*この記号を表示させる*/
	transform: scale(0.5);	/*実寸の50%に縮小する指定*/
	padding-right: 10px;	/*記号とテキストとの間にとる余白*/
	margin-left: -0.4em;	/*少しだけ左側に移動する*/
}

/*mainブロックのpタグ*/
main p {
	margin: 0 20px 30px;
	font-size: 13px;
}


/*フッター設定
---------------------------------------------------------------------------*/
footer small {font-size: 100%;}

footer {
	font-size: 0.7rem;		/*文字サイズ*/
	text-align: center;		/*内容をセンタリング*/
	padding: 20px;			/*ボックス内の余白*/
	background: #111;		/*背景色*/
	color: #fff;			/*文字色*/
}

/*リンクテキスト*/
footer a {text-decoration: none;color: #fff;}

/*著作部分*/
footer .pr {display: block;}


/*フッターメニュー
---------------------------------------------------------------------------*/
/*メニューブロック全体*/
#footermenu {
	margin: 0;padding: 0;
	margin-bottom: 15px;	/*下に空けるスペース*/
}

/*メニュー１個あたり*/
#footermenu li {
	display: inline-block;	/*横並びにする*/
	padding: 0 10px;		/*上下、左右への余白*/
	font-size: 1.2em;
}


/*スライドショー（slickを使用）
---------------------------------------------------------------------------*/
.mainimg {
	position: relative;
}

/*丸いページナビボタン全体を囲むブロック*/
ul.slick-dots {
	margin:0;padding: 0;
	line-height: 1;
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 10px;	/*下からの配置場所指定*/
}

/*丸いページナビボタン１個あたりの設定*/
ul.slick-dots li {
	display: inline-block;
	margin: 0 10px;
	cursor: pointer;
}

/*buttonタグ*/
ul.slick-dots li button {
	border: none;padding: 0;
	display: block;
	text-indent: -9999px;	/*デフォルトで文字が出るので画面の外に追い出す指定*/
	width: 12px;			/*ボタンの幅*/
	height: 12px;			/*ボタンの高さ*/
	border-radius: 50%;		/*丸くする指定*/
	cursor: pointer;		/*クリックで画像へジャンプするので、わかりやすいようhover時にpointerになるように。*/
	background: #fff;		/*背景色。白。*/	
}

/*buttonのアクティブ時（現在表示されている画像を示すボタン）*/
ul.slick-dots li.slick-active button {
	background: #ff0e0e;	/*色*/
}


/*「お知らせ」ブロック
---------------------------------------------------------------------------*/
/*お知らせブロック*/
#new {
	margin: 0;
	display: flex;		/*flexボックスを使う指定*/
	flex-wrap: wrap;	/*折り返す指定*/
	padding: 0 20px;	/*上下、左右へのボックス内の余白*/
}

/*日付(dt)、記事(dd)共通設定*/
#new dt,
#new dd {
	padding: 5px 0;		/*上下、左右へのボックス内の余白*/
}

/*日付(dt)設定*/
#new dt {
	width: 8em;	/*幅。8文字(em)分。※下の「900px以上」の端末用の設定に再設定があります。*/
}

/*日付の横のマーク（共通設定）*/
#new dt span {
	display: none;	/*小さな端末では非表示にしておく。*/
}

/*記事(dd)設定*/
#new dd {
	width: calc(100% - 8em);	/*「8em」は上の「#new dt」のwidthの値です。※下の「900px以上」の端末用の設定に再設定があります。*/
}

/* Product Video and Expo Image */
.product-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.product-content .erucore-text {
	font-size: 1.1em;
	margin-bottom: 15px;
}
.product-content .erucore-text a {
	font-size: 1.1em;
	font-weight: bold;
}

.product-content .erucore-link {
	text-align: right;
	font-size: 1.1em;
	margin-top: 5px;
}

.erucore-logo {
	width: 60%;
	margin-left: calc(50% - 35%);
}

.product-content .video-container {
  margin-bottom: 10px;
}

.product-content .video-player {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
}

.product-content .expo-container {
  margin: 20px 0;
  text-align: center;
}

.product-content .expo-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/*listブロック
---------------------------------------------------------------------------*/
.list {
	margin-bottom: 30px;	/*ボックス同士の上下間に空けるスペース*/
	padding: 20px;			/*ボックス内の余白*/
	background: #fff;		/*背景色*/
	color: #999;			/*文字色*/
	box-shadow: 5px 5px 20px rgba(0,0,0,0.1);	/*ボックスの影。右へ、下へ、ぼかし幅、0,0,0は黒の事で0.1は色が10%出た状態。*/
	position: relative;
}

/*ボックス内のh4タグ*/
.list h4 {
	margin: 0;
	color: #666;	/*文字色*/
}
.list h4 a {
	color: #666;	/*リンクテキストの文字色*/
}

/*ボックス内のpタグ*/
.list p {
	margin: 0;
	font-size: 0.8em;	/*文字サイズを80%に*/
}

/*list内のNEWマーク*/
.list .new {
	font-size: 0.6em;		/*文字サイズ*/
	background: #ff0000;	/*背景色*/
	color: #fff;			/*文字色*/
	width: 50px;			/*幅*/
	line-height: 50px;		/*高さ*/
	border-radius: 50%;		/*角丸のサイズ。円形になります。*/
	text-align: center;		/*テキストをセンタリング*/
	position: absolute;
	left: -10px;			/*listブロックに対して左からの配置場所の指定*/
	top: -10px;				/*listブロックに対して上からの配置場所の指定*/
	transform: rotate(-30deg);	/*回転。まっすぐ表示させたいならこの１行を削除。*/
}

/*list内でのbtn*/
.list .btn {
	margin-top: 1em;	/*ボタンの上に１文字分のスペースを空ける*/
}
.list .btn a {
	display: block;
}


/*FAQ
---------------------------------------------------------------------------*/
/*FAQボックス全体*/
.faq {
	padding: 0 5px;	/*上下、左右へのボックス内の余白*/
}

/*質問*/
.faq dt {
	border-radius: 3px;		/*枠を角丸にする指定*/
	margin-bottom: 20px;	/*下に空けるスペース*/
	background: linear-gradient(#fff, #f7f7f7);	/*背景グラデーション*/
	text-indent: -2em;				/*テキストのインデント。質問が複数行になった際に、テキストの冒頭を揃える為に設定しています。*/
	padding: 5px 1em 5px 3em;		/*ボックス内の余白。ここを変更する場合、上のtext-indentも調整します。*/
	border: 1px solid #e4e2d7;		/*枠線の幅、線種、色*/
}

/*アイコン（Font Awesome）*/
.faq dt::before {
	font-family: "Font Awesome 5 Free";	/*Font Awesomeを使う指定*/
	content: "\f007";		/*使いたいアイコン名をここで指定。Font Awesomeに記載されています。詳しくは当テンプレートのマニュアルを読んで下さい。*/
	color: #770000;			/*アイコンの色*/
	padding-right: 1em;		/*アイコンとテキストの間の余白*/
}

/*回答*/
.faq dd {
	padding: 5px 1em 30px 3em;		/*ボックス内の余白**/
}

/*opencloseを適用した要素のカーソル*/
.openclose {
	cursor: pointer;	/*カーソルの形状。リンクと同じスタイルにしてクリックできると認識してもらう。*/
}

.faq dt span {text-indent: 0;}


/*btnの設定
---------------------------------------------------------------------------*/
p.btn {margin: 0;}

/*ボタンを囲むブロック*/
.btn {
	text-align: center;	/*内容をセンタリング*/
}

/*ボタン*/
.btn a,
.btn input {
	display: inline-block;text-decoration: none;border: none;
	font-size: 1em;
	box-shadow: 2px 2px 5px rgba(0,0,0,0.2);	/*ボックスの影。右へ、下へ、ぼかし幅の順。0,0,0は黒の事で0.2は色が20%出た状態。*/
	background: linear-gradient(150deg, #ff0e0e, #770000);/*背景グラデーション。#logoと合わせています。*/
	letter-spacing: 0.1em;	/*文字間隔を少し広くする指定*/
	color: #fff;			/*文字色*/
	transition: 0.3s;		/*hoverまでにかける時間。0.3秒。*/
	padding: 1em 2em;		/*余白*/
	margin: 0 auto 30px;	/*ボタンの外側に空けるスペース。上、左右、下への順番。*/
}

/*ボタンのマウスオン時*/
.btn a:hover,
.btn input:hover {
	cursor: pointer;		/*inputタグを使う場合に「手」のマークになるように。リンクと同じ表示になるようにという事です。*/
	opacity: 0.8;			/*冒頭のリンクテキストのhoverと合わせました*/
	transform: scale(1.02);	/*実寸の102%に拡大*/
}

/*listブロック内のボタン*/
.list .btn a {
	margin: 0;
}


/*テーブル
---------------------------------------------------------------------------*/
/*テーブル１行目に入った見出し部分（※caption）*/
.ta1 caption {
	font-weight: bold;		/*太字に*/
	padding: 10px 5px;		/*上下、左右へのボックス内の余白。基本的に数行下の「.ta1 th, .ta1 td」のpaddingと揃えておけばOKです。*/
	background: #eee;		/*背景色*/
	margin-bottom: 15px;	/*下に空けるスペース*/
}

/*ta1テーブルブロック設定*/
.ta1 {
	border-top: 1px solid #ccc;	/*テーブルの一番上の線。幅、線種、色*/
	width: 100%;
	margin: 0 auto 30px;		/*最後の「30px」がテーブルの下に空けるスペースです*/
}

/*tr（１行分）タグ設定*/
.ta1 tr {
	border-bottom: 1px solid #ccc;	/*テーブルの下線。幅、線種、色*/
}

/*th（左側）、td（右側）の共通設定*/
.ta1 th, .ta1 td {
	padding: 10px 5px;		/*上下、左右へのボックス内の余白*。基本的に数行上の「.ta1 caption」のpaddingと揃えておけばOKです。*/
	word-break: break-all;	/*英語などのテキストを改行で自動的に折り返す設定。これがないと、テーブルを突き抜けて表示される場合があります。*/
}

/*th（左側）のみの設定*/
.ta1 th {
	width: 30%;			/*幅*/
	text-align: left;	/*左よせにする*/
}


/*背景色パターン
---------------------------------------------------------------------------*/
.bg1 {
	background: #eee;
}


/*PAGE TOP（↑）設定
---------------------------------------------------------------------------*/
.pagetop-show {display: block;}

/*ボタンの設定*/
.pagetop a {
	display: block;text-decoration: none;text-align: center;z-index: 99;
	position: fixed;	/*スクロールに追従しない(固定で表示)為の設定*/
	right: 20px;		/*右からの配置場所指定*/
	bottom: 20px;		/*下からの配置場所指定*/
	color: #fff;		/*文字色*/
	font-size: 1.5em;	/*文字サイズ*/
	background: rgba(0,0,0,0.2);	/*背景色。0,0,0は黒の事で0.2は色が20%出た状態。*/
	width: 60px;		/*幅*/
	line-height: 60px;	/*高さ*/
	border-radius: 50%;	/*円形にする*/
}


/*その他
---------------------------------------------------------------------------*/
.clearfix::after {content: "";display: block;clear: both;}
.color-theme, .color-theme a {color: #770000 !important;}
.color-check, .color-check a {color: #f00 !important;}
.c {text-align: center !important;}
.ws {width: 95%;display: block;}
.wl {width: 95%;display: block;}
.mb30 {margin-bottom: 30px !important;}
.mt30 {margin-top: 30px !important;}
.look {display: inline-block;padding: 0px 10px;background: #666; color: #fff; border-radius: 3px;margin: 5px 0; word-break: break-all;}
.look .color-check {color: #ffcc00 !important;}
.small {font-size: 0.6em;}






/*---------------------------------------------------------------------------
ここから下は画面幅600px以上の追加指定
---------------------------------------------------------------------------*/
@media screen and (min-width:600px) {


/*listブロック
---------------------------------------------------------------------------*/
.list {
	display: flex;					/*flexボックスを使う指定*/
	justify-content: space-between;	/*並びかたの種類の指定*/
}

/*ボックス内のfigure画像*/
.list figure {
	width: 30%;
	margin-right: 5%;
}

/*ボックス内のtextブロック*/
.list .text {
	flex: 1;
	margin-right: 5%;
}


/*　※注意！　下の閉じカッコ　}　はこのブロックに必要なので、削除しないで下さい。　*/

}





/*---------------------------------------------------------------------------
ここから下は画面幅900px以上の追加指定
---------------------------------------------------------------------------*/
@media screen and (min-width:900px) {


/*全体の設定
---------------------------------------------------------------------------*/
html, body {
	font-size: 15px;	/*基準となるフォントサイズの上書き*/
}


/*header（ロゴなどが入った最上段のブロック）
---------------------------------------------------------------------------*/
/*ヘッダーブロック*/
header {
	position: fixed;	/*スクロールしても一緒に移動しない為の設定。画面上に固定表示されます。*/
	width: 100%;
}


/*menubarブロック設定
---------------------------------------------------------------------------*/
/*メニューブロックの設定*/
#menubar {
	font-size: 0.85em;	/*文字サイズを少し小さくする*/
}
#menubar ul {
	display: flex;	/*flexボックスを使う指定*/
}

/*メニュー１個あたりの設定*/
#menubar a {
	padding: 10px 15px;	/*上下、左右への余白*/
}


/*ドロップダウンメニュー
---------------------------------------------------------------------------*/
#menubar .ddmenu_parent ul {
	position: absolute;
}


/*３本バー（ハンバーガー）アイコン設定
---------------------------------------------------------------------------*/
/*ハンバーガーメニューを非表示にする*/
#menubar_hdr {display: none;}


/*「お知らせ」ブロック
---------------------------------------------------------------------------*/
/*日付(dt)設定*/
#new dt {
	width: 14em;	/*幅。14文字(em)分。*/
	display: flex;	/*flexボックスを使う指定*/
	justify-content: space-between;	/*日付とアイコンをそれぞれ端に寄せる*/
}

/*日付の横のマーク（共通設定）*/
#new dt span {
	display: inline-block;	/*表示させる*/
	width: 6em;				/*幅。6文字(em)分。*/
	background: #999;		/*背景色*/
	color: #fff;			/*文字色*/
	font-size: 0.8em;		/*文字サイズを80%に。*/
	text-align: center;		/*文字をセンタリング*/
	margin-right: 1em;		/*アイコンの右側に空けるスペース*/
	align-self: flex-start;	/*高さを間延びさせない指定*/
	line-height: 1.8;		/*行間を少し狭く*/
	position: relative;top: 0.4em;	/*上下の配置バランスの微調整*/
}

/*bg1設定。サンプルテンプレートでは「サービス」と書いてあるマーク*/
#new dt span.icon-bg1 {
	background: #770000;	/*背景色*/
}
#new dt span.icon-bg3 {
	background: #001677;	/*背景色*/
}

/*記事(dd)設定*/
#new dd {
	width: calc(100% - 14em);	/*「14em」は上の「#new dt」のwidthの値です。*/
}


/*listブロック
---------------------------------------------------------------------------*/
/*listブロック全体を囲むブロック*/
.list-container {
	display: flex;		/*flexボックスを使う指定*/
	flex-wrap: wrap;	/*折り返す指定*/
	justify-content: space-between;	/*並びかたの種類の指定*/
}

/*１個あたりのボックス設定*/
.list {
	flex-direction: column;			/*子要素を縦並びにする*/
	width: 32%;		/*幅。３列になります。*/
}

/*ボックス内のfigure画像*/
.list figure {
	width: 100%;
	margin-right: 0;
}

/*ボックス内のtextブロック*/
.list .text {
	margin-right: 0;
}

/*ボックス内のh4タグ*/
.list h4 {
	margin: 10px 0;	/*上下、左右への余白*/
}


/*テーブル
---------------------------------------------------------------------------*/
/*テーブル１行目に入った見出し部分（※caption）*/
.ta1 caption {
	padding: 5px 15px;		/*上下、左右へのボックス内の余白*/
}

/*th（左側）、td（右側）の共通設定*/
.ta1 th, .ta1 td {
	padding: 20px 15px;		/*上下、左右へのボックス内の余白*/
}

/*th（左側）のみの設定*/
.ta1 th {
	width: 20%;		/*幅*/
}


/*その他
---------------------------------------------------------------------------*/
.ws {width: 48%;display: inline;}


/*　※注意！　下の閉じカッコ　}　はこのブロックに必要なので、削除しないで下さい。　*/

}

video {
	width: 100%;
	max-width: 400px;
  }