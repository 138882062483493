@charset "utf-8";


/*animation1のキーフレーム設定
---------------------------------------------------------------------------*/
@keyframes animation1 {
	0% {left: -200px;}
	100% {left: 0px;}
}


/*opa1のキーフレーム設定
---------------------------------------------------------------------------*/
@keyframes opa1 {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
