/* client-homepage/src/components/Home/Contact/ContactForm.css */

/* コンタクトページ全体のスタイル */
.contact-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.contact-page h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

/* フォームのスタイル */
.contact-form {
  width: 100%;
  max-width: 500px;
  margin: 30px auto;
  padding: 25px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.form-group label .optional {
  font-weight: normal;
  color: #666;
  font-size: 14px;
  margin-left: 8px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  font-size: 14px;
  color: #999;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

/* 送信ボタン用のスタイル */
.contact-form .submit-button {
  width: 300px;
  padding: 13px;
  background-color: #F42859;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  font-weight: normal;
}

/* プライバシーポリシーリンク用のスタイル */
.contact-form .privacy-policy-link {
  color: #1646dd;
  background: none;
  border: none;
  padding: 2px 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  width: auto;
}

.contact-form .privacy-policy-link:hover {
  background-color: rgba(22, 70, 221, 0.1);
  padding: 2px 5px;
  border-radius: 4px;
  text-decoration: none;
}

/* エラー表示のスタイル */
.error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.character-count {
  text-align: right;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

/* ボタンのスタイル */
button {
  width: 100px;
  padding: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

button:hover {
  background-color: #0056b3;
}

button:disabled,
button.submitting {
  background-color: #ccc;
  cursor: not-allowed;
}

/* メッセージ表示のスタイル */
.submit-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
}

.submit-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.submit-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* レスポンシブ */
@media screen and (max-width: 768px) {
  .contact-page {
    padding: 30px 15px;
  }

  .contact-form {
    width: 95%;
    padding: 20px;
    margin: 25px auto;
  }

  .form-group {
    margin-bottom: 18px;
  }

  .form-group label {
    font-size: 15px;
  }

  .form-group label .optional {
    font-size: 13px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 15px;
    padding: 9px 12px;
  }

  .form-group input::placeholder,
  .form-group textarea::placeholder {
    font-size: 13px;
  }

  .form-group textarea {
    height: 120px;
  }

  button {
    width: 110px;
    padding: 9px;
    font-size: 15px;
  }

  .submit-message {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .contact-page {
    padding: 20px 10px;
  }

  .contact-form {
    width: 90%;
    padding: 15px;
    margin: 20px auto;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group label .optional {
    font-size: 12px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
    padding: 8px 10px;
  }

  .form-group input::placeholder,
  .form-group textarea::placeholder {
    font-size: 12px;
  }

  .form-group textarea {
    height: 100px;
  }

  button {
    width: 100px;
    padding: 8px;
    font-size: 14px;
  }

  .submit-message {
    font-size: 14px;
    padding: 8px;
  }

  .error {
    font-size: 12px;
  }

  .character-count {
    font-size: 12px;
  }
}