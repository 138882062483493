/* src/App.css */
/* Reactアプリケーション固有のスタイル */

/* App全体のコンテナスタイル */
#root {
  width: 100%;
  min-height: 100vh;
}

/* アプリケーション全体に適用されるグローバルスタイル */
.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* main要素の調整 */
main {
  flex: 1 0 auto;
}

/* ヘッダー関連の調整 */
header {
  height: 45.5px;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

/* ロゴまわりの調整 */
header #logo {
  width: 150px;
  height: 148.38px;
  position: absolute;
  left: 3%;
  top: 0;
  background: linear-gradient(150deg, #ff0e0e, #770000);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  padding: 0;
}

header #logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

header #logo img {
  width: 100%;
  height: auto;
  display: block;
}

/* メニュー調整 */
#menubar {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 3%;
}

#menubar ul {
  display: flex;
  gap: 20px;
}

#menubar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0.85em;
}

/* ハンバーガーメニュー */
#menubar_hdr {
  top: 15px;
}

/* メインビジュアルの位置調整 */
.mainimg {
  margin-top: 0;
}

/* SE・PGプルダウン */
.openclose {
  cursor: pointer;
  transition: background 0.3s;
  position: relative;
}

/* 矢印アイコンの追加とアニメーション */
.openclose::after {
  content: "\f107";  /* Font Awesomeの下向き矢印 */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 1em;
  transition: transform 0.3s;
}

/* 開いているときの矢印の回転 */
.openclose.active::after {
  transform: rotate(180deg);
}

/* クリック時の背景色変化 */
.openclose:hover {
  background: linear-gradient(#f7f7f7, #fff);
}

/* 開閉アニメーション */
.faq dd {
  transition: all 0.3s ease-out;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  /* メニューを非表示 */
  #menubar {
    display: none;
  }

  /* メニュー表示時のスタイル */
  #menubar.db {
    display: block;
    position: fixed;
    overflow: auto;
    z-index: 100;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 70px 0;
    background: rgba(0,0,0,0.9);
    color: #fff;
    animation: animation1 0.2s both;
    text-align: center;
  }

  /* メニュー項目のスタイル */
  #menubar.db ul {
    display: block;
  }

  #menubar.db a {
    color: #fff;
    padding: 20px;
    display: block;
  }

  /* レスポンシブ時はヘッダーが消えるように */
  header {
    background: transparent !important;
  }

  /* ヘッダーナビゲーションを非表示 */
  header nav {
    display: none;
  }

  header.menu-open {
    background: #111 !important;
  }
}

/* アニメーション */
@keyframes animation1 {
  0% {
    left: -200px;
  }
  100% {
    left: 0px;
  }
}