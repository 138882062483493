/* メインコンテンツ */
.privacy-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 7% 5%;
  line-height: 1.6;
}

/* 見出しのスタイル */
.privacy-content h1 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2em;
}

.privacy-content h2 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.2rem;
  font-weight: bold;
}

/* 段落のスタイル */
.privacy-content p {
  margin-bottom: 1em;
}

.privacy-content .contact-info {
  line-height: 1.3;
}

/* リストのスタイル */
.privacy-content ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.privacy-content ol > li {
  margin-bottom: 0.5em;
  list-style-type: decimal;  /* 1. 2. 3. ... */
}

/* ネストされたリストのスタイル */
.privacy-content ol > li > ol {
  margin-top: 0.5em;
  padding-left: 2em;
}

.privacy-content ol > li > ol > li {
  list-style-type: lower-alpha;  /* a. b. c. ... */
}

.privacy-content ol > li > ol > li > ul {
  padding-left: 2em;
  margin-top: 0.5em;
}

.privacy-content ol > li > ol > li > ul > li {
  list-style-type: disc;  /* ・ ... */
  margin-bottom: 0.3em;
}

.privacy-content li {
  margin-bottom: 0.5em;
}

/* 日付表示 */
.policy-date {
  margin-top: 2em;
  text-align: right;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
  .privacy-content {
    padding: 10% 5%;
  }

  .privacy-content h1 {
    font-size: 1.5rem;
  }

  .privacy-content h2 {
    font-size: 1.1rem;
  }
}