.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dialog-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header h2 {
  margin: 0;
  font-size: 1.5rem;
  border: none;
}

.dialog-body {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.privacy-policy-content {
  line-height: 1.6;
}

.privacy-policy-content h2 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.2rem;
  font-weight: bold;
}

.privacy-policy-content p {
  margin-bottom: 1em;
}

.privacy-policy-content .contact-info {
  line-height: 1.4;
}

.privacy-policy-content ol {
  padding-left: 3em;
  margin-bottom: 1em;
}

.privacy-policy-content ol > li {
  margin-bottom: 0.5em;
  list-style-type: decimal;  /* 1. 2. 3. ... */
}

/* ネストされたリストのスタイル */
.privacy-policy-content ol > li > ol {
  margin-top: 0.5em;
  padding-left: 2em;
}

.privacy-policy-content ol > li > ol > li {
  list-style-type: lower-alpha;  /* a. b. c. ... */
}

/* さらにネストされた場合 */
.privacy-policy-content ol > li > ol > li > ul {
  padding-left: 2em;
  margin-top: 0.5em;
}

.privacy-policy-content ol > li > ol > li > ul > li {
  list-style-type: disc;  /* ・ ... */
  margin-bottom: 0.3em;
}

.policy-date {
  margin-top: 2em;
  text-align: right;
}

@media (max-width: 768px) {
  .dialog-content {
    width: 95%;
    max-height: 85vh;
  }

  .dialog-header h2 {
    font-size: 1.2rem;
  }

  .dialog-body {
    padding: 15px;
  }
}