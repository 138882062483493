@charset "utf-8";



/*up,down,left,right,transform1,transform2の共通設定
（待機中の設定）
---------------------------------------------------------------------------*/
.up, .down, .left, .right, .transform1, .transform2 {
	position: relative;
	opacity: 0;		/*透明度（透明の状態）*/
}


/*up,down,left,right,transform1,transform2の共通設定
（要素が見えたら実行するアクションの設定）
---------------------------------------------------------------------------*/
.upstyle, .downstyle, .leftstyle, .rightstyle, .transform1style, .transform2style {
	opacity: 1;				/*透明度（色が100%出た状態）*/
	transition: 1s 0.5s;	/*1sはアニメーションの実行時間1.5秒。0.5sは0.5秒遅れてスタートする指定。*/
}


/*upスタイル。下から上にフェードインしてくるスタイル
---------------------------------------------------------------------------*/
/*待機中の設定*/
.up {
	bottom: -20px;	/*基準値の下20pxの場所からスタート*/
}

/*要素が見えたら実行するアクション*/
.upstyle {
	bottom: 0px;	/*基準値まで戻す*/
}


/*downスタイル。上から下にフェードインしてくるスタイル
---------------------------------------------------------------------------*/
/*待機中の設定*/
.down {
	top: -20px;	/*基準値の上20pxの場所からスタート*/
}

/*要素が見えたら実行するアクション*/
.downstyle {
	top: 0px;	/*基準値まで戻す*/
}


/*leftスタイル。左からフェードインしてくるスタイル
---------------------------------------------------------------------------*/
/*待機中の設定*/
.left {
	left: -20px;	/*基準値より左に100pxの場所からスタート*/
}

/*要素が見えたら実行するアクション*/
.leftstyle {
	left: 0px;		/*基準値まで戻す*/
}


/*rightスタイル。右からフェードインしてくるスタイル
---------------------------------------------------------------------------*/
/*待機中の設定*/
.right {
	right: -20px;	/*基準値より右に100pxの場所からスタート*/
}

/*要素が見えたら実行するアクション*/
.rightstyle {
	right: 0px;		/*基準値まで戻す*/
}


/*transform1スタイル。その場で回転するスタイル
---------------------------------------------------------------------------*/
/*待機中の設定*/
.transform1 {
	transform: scaleX(0);	/*幅を0%でスタート*/
}

/*要素が見えたら実行するアクション*/
.transform1style {
	transform: scaleX(1);	/*幅を100%に戻す*/
}


/*transform2スタイル。倒れた状態から起き上がるスタイル
---------------------------------------------------------------------------*/
/*待機中の設定*/
.transform2 {
	transform: perspective(400px) translateZ(-70px) rotateX(50deg);
}

/*要素が見えたら実行するアクション*/
.transform2style {
	transform: perspective(400px) translateZ(0px) rotateX(0deg);
}


/*blurスタイル。ぼかしで出現するスタイル。
---------------------------------------------------------------------------*/
/*待機中の設定*/
.blur {
	opacity: 0;	/*透明度（透明の状態）*/
	filter: blur(30px);
	transform: scale(1.1);
}

/*要素が見えたら実行するアクション*/
.blurstyle {
	opacity: 1;
	filter: blur(0);
	transform: scale(1);
	transition: 1s 0.5s;
}


/*transform3スタイル。小さなサイズから原寸大になるスタイル
---------------------------------------------------------------------------*/
/*キーフレーム（アニメーション）設定*/
@keyframes transform3 {
0% {
	transform: scale(0);
}
50% {
	transform: scale(1.05);
}
70% {
	transform: scale(0.99);
}
100% {
	transform: scale(1);
}
}

/*待機中の設定*/
.transform3 {
	transform: scale(0);
}

/*要素が見えたら実行するアクション*/
.transform3style {
	animation: transform3 0.8s ease-out 0.5s both;
}
